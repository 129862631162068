import { useField } from "vee-validate";
import type { ExtractPropTypes, Ref } from "vue";

import { type FormState, FormStateKey } from "./form.hook";

let index = 0;
export const uniqFieldName = () => `Field ${index++}`;

export const appFormInputProperties = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modelValue: { type: String as any, default: undefined },
  label: { type: String, required: true },
  nonRequiredMention: { type: Boolean, default: false },
  name: { type: String, default: undefined },
  required: { type: Boolean, default: false },
  type: { type: String, default: "text" },
  readonly: { type: Boolean, default: false },
  prependIcon: { type: String, default: undefined },
  autofocus: { type: Boolean, default: false },
  hideLabel: { type: Boolean, default: false },
  placeholder: { type: String, default: undefined },
  rules: { type: Object, default: () => ({}) },
  mode: { type: String, default: undefined },
  tooltip: { type: String, default: undefined },
  size: { type: String, default: undefined },
  border: { type: Boolean, default: true },
};

export const useAppFormInput = (
  properties: ExtractPropTypes<typeof appFormInputProperties>,
) => {
  const parentFormState = inject<FormState | null>(FormStateKey, null);
  const name = properties.name ?? properties.label ?? uniqFieldName();
  const componentInstance = getCurrentInstance();

  const { value, errorMessage, handleChange } = useField(
    name,
    computed(() => {
      return {
        required: properties.required,
        email: properties.type === "email",
        ...properties.rules,
      };
    }),
    {
      validateOnValueUpdate: false,
      initialValue: properties.modelValue,
    },
  );

  const isInvalid = computed(() => !!errorMessage.value);
  const isReadonly = computed(
    () =>
      parentFormState?.submitting.value ||
      parentFormState?.readonly.value ||
      properties.readonly,
  );

  /*
  Totally inspired by https://vee-validate.logaretm.com/v4/guide/composition-api/validation#validation-behavior
   */
  const listeners = computed(() => {
    if (isReadonly.value) return {};

    if (!isInvalid.value) {
      return {
        blur: (event: InputEvent) => {
          componentInstance?.emit("blur");
          return handleChange(event, false);
        },
        change: (event: InputEvent) =>
          handleChange(event, properties.mode !== "lazy"),
        input: (event: InputEvent) => handleChange(event, false),
        focus(event: InputEvent) {
          componentInstance?.emit("focus", event);
        },
      };
    }

    return {
      blur: (event: InputEvent) => {
        componentInstance?.emit("blur");
        handleChange(event);
      },
      change: (event: InputEvent) =>
        handleChange(event, properties.mode !== "lazy"),
      input: (event: InputEvent) =>
        handleChange(event, properties.mode !== "lazy"),
      focus(event: InputEvent) {
        componentInstance?.emit("focus", event);
      },
    };
  });

  const fieldBinding = computed(() => {
    return {
      invalid: isInvalid.value,
      value: value.value as string,
      label: properties.label ?? "",
      name,
      prependIcon: properties.prependIcon,
      readonly: isReadonly.value,
      nonRequiredMention: properties.nonRequiredMention,
      hideLabel: properties.hideLabel,
      size: properties.size as "small" | undefined,
      border: properties.border,
    };
  });

  return {
    value: value as Ref<typeof properties.modelValue>,
    isReadonly,
    listeners,
    fieldBinding,
    handleChange,
  };
};

export { defineRule, useField } from "vee-validate";
