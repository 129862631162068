<template>
  <div
    class="group flex min-h-[48px] scroll-mt-[--top-header-height] rounded border bg-white font-normal text-black transition-all"
    :class="{
      '!min-h-[38px]': size === 'small',
      '!border-error !bg-error/5': invalid,
      '!bg-disabled cursor-emoji-[🚫]': readonly,
      'focus-within:border-form-focus': !readonly,
      'border-0': !border,
    }"
    :data-form-field="name"
    :style="{
      '--floatingElYOffset': size === 'small' ? '8px' : '13px',
      '--floatingElYOffsetActive': size === 'small' ? '-8px' : '-11px',
    }"
  >
    <div v-if="prependIcon || $slots.prepend" class="relative w-6 shrink-0">
      <slot name="prepend">
        <app-icon
          v-if="prependIcon"
          class="mt-px"
          :class="[
            floatingElementsClasses,
            { 'group-focus-within:text-primary': !readonly },
          ]"
          :icon="prependIcon"
          :size="18"
        />
      </slot>
    </div>

    <component :is="labelElementAs ?? 'label'" class="relative flex-auto">
      <span
        class="text-sm font-semibold"
        :class="[floatingElementsClasses, { 'sr-only': hideLabel }]"
        >{{ label }}
        <span
          v-if="nonRequiredMention"
          class="font-normal opacity-50 text-xs hidden sm:inline"
          >({{ $t("app.form.labels.optional_field") }})</span
        >
      </span>

      <slot
        v-bind="{
          inputClass: [
            'text-left outline-none relative !bg-transparent h-full w-full px-2 pt-4 text-subtle md:text-sm',
            {
              'cursor-emoji-[🚫]': readonly,
              '!py-2 !text-base': hideLabel,
              '!text-sm': size === 'small',
            },
          ],
        }"
      />
    </component>

    <div
      v-if="$slots.append"
      class="flex items-center justify-center py-2 pr-2"
    >
      <slot name="append" />
    </div>
  </div>
</template>

<script lang="ts" setup>
const properties = defineProps<{
  label: string;
  name: string;
  labelElementAs?: "div";
  prependIcon?: string;
  invalid?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  readonly?: boolean;
  nonRequiredMention?: boolean;
  hideLabel?: boolean;
  size?: "small";
  border?: boolean;
}>();

const floatingElementsClasses = computed(() => {
  return [
    "origin-top-left absolute left-2 top-[--floatingElYOffset] transition-all",
    {
      "scale-90 translate-y-[--floatingElYOffsetActive]":
        !properties.hideLabel &&
        (!!properties.value?.length || properties.invalid),
      "text-error": properties.invalid,
      "group-focus-within:translate-y-[--floatingElYOffsetActive] group-focus-within:scale-90":
        !properties.hideLabel && !properties.readonly,
    },
  ];
});
</script>
